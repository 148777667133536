import useSearchQueryParameter, {
  SearchQueryParameterResponse,
} from "@/hooks/useSearchQueryParameter";
import { useMemo } from "react";
import usePagination, { PaginationResponse } from "@/hooks/usePagination";
import {
  ColorType,
  FireProtectionMaterial,
  FireResistance,
  FlamePreventionRegulation,
  Formaldehyde,
  ProductKind,
  ProductVariationWithProductResponse,
} from "@/web-client/api";
import client from "@/utils/api/client";
import { ProductSearchQueryParameter } from "@/types/ProductSearchQueryParameter";

interface UseProductSearchReturn {
  pagination: PaginationResponse<ProductVariationWithProductResponse>;
  parameter: SearchQueryParameterResponse<ProductSearchQueryParameter>;
  categoryId?: number;
  keyword?: string;
  inStock: boolean;
}

const useProductSearch = (
  path: string,
  kind: ProductKind,
  defaultQuery = {},
  limit = 20,
): UseProductSearchReturn => {
  const parameter = useSearchQueryParameter<ProductSearchQueryParameter>(
    path,
    defaultQuery,
  );

  const {
    category_id,
    keyword,
    colors,
    formaldehyde,
    flame_prevention_regulation,
    fire_protection_material,
    fire_registance,
    material_id,
    min_width,
    max_width,
    min_height,
    max_height,
    min_depth,
    max_depth,
    min_price,
    max_price,
    in_stock,
  } = parameter.query;

  const categoryId = useMemo<number | undefined>(() => {
    return category_id ? Number(category_id) : undefined;
  }, [category_id]);

  const inStock = useMemo<boolean>(() => {
    return in_stock ? Boolean(in_stock) : false;
  }, [in_stock]);

  const pagination = usePagination<ProductVariationWithProductResponse>(
    parameter.queryController.toPath(),
    limit,
    async ({ limit, page }) => {
      const { data } = await client.productVariationsSearchGet({
        keyword,
        kind,
        categoryId: category_id ? Number(category_id) : undefined,
        colorTypeIds: colors
          ? colors.split(",").map((v) => Number(v) as ColorType)
          : [],
        fireProtectionMaterial: fire_protection_material
          ? (Number(fire_protection_material) as FireProtectionMaterial)
          : undefined,
        flamePreventionRegulation: flame_prevention_regulation
          ? (Number(flame_prevention_regulation) as FlamePreventionRegulation)
          : undefined,
        formaldehyde: formaldehyde
          ? (Number(formaldehyde) as Formaldehyde)
          : undefined,
        fireResistance: fire_registance
          ? (Number(fire_registance) as FireResistance)
          : undefined,
        materialId: material_id ? Number(material_id) : undefined,
        minWidth: min_width ? Number(min_width) : undefined,
        maxWidth: max_width ? Number(max_width) : undefined,
        minHeight: min_height ? Number(min_height) : undefined,
        maxHeight: max_height ? Number(max_height) : undefined,
        minDepth: min_depth ? Number(min_depth) : undefined,
        maxDepth: max_depth ? Number(max_depth) : undefined,
        minPrice: min_price ? Number(min_price) : undefined,
        maxPrice: max_price ? Number(max_price) : undefined,
        inStock: in_stock ? Boolean(in_stock) : undefined,
        limit,
        offset: limit * (page - 1),
      });

      return data;
    },
    { waitForAuth: true },
  );

  return {
    inStock,
    categoryId,
    keyword,
    pagination,
    parameter,
  };
};

export default useProductSearch;
